<template>
  <!-- 行业一码通-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <img class="img" :src="img1">
      </div>
      <div class="link1">
        <div class="link1_title">
          <div class="link1_title-big">应用场景</div>
          <div class="link1_title-small">多场景使用，方便快捷</div>
        </div>
        <div class="forms1">
          <div class="form1" v-for="(form,index) in forms1" :key="index">
            <div class="form1_icon">
              <img class="form1_img" :src="form.img">
            </div>
            <div class="form1_title">{{form.title}}</div>
            <div class="form1_text">{{form.text}}</div>
          </div>
        </div>
      </div>
      <Reserve></Reserve>
      <div class="link2">
        <div class="link2_title">
          <div class="link2_title-big">全购热门产品</div>
            <div class="link2_title-small">低成本创业必选</div>
        </div>
        <div class="forms2" v-for="(form,index) in forms2" :key="index">
          <div class="form2">
            <div class="form2_icon">
              <img class="img" :src="form.img">
            </div>
            <div class="form2_solid"></div>
            <div class="form2_content">
              <div class="form2_title">{{form.title}}</div>
              <div class="form2_text">{{form.text}}</div>
            </div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page6',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page6.1.png'),
      forms1: [{
        img: require('@/assets/img/page6.2.1.png'),
        title: '商务品牌推广',
        text: '可在商品上、墙体上、广告牌贴上二维码，用户被吸引后就会扫码查看，达到品牌推广'
      }, {
        img: require('@/assets/img/page6.2.2.png'),
        title: '线上与线下服务',
        text: '线上用户通过二维码预约服务，线下用户到店享受服务，线上线下结合'
      }, {
        img: require('@/assets/img/page6.2.3.png'),
        title: '业务合作与协同',
        text: '实现企业内部业务合作系统，企业与企业之间业务合作，让业务合作与协同数字化'
      }, {
        img: require('@/assets/img/page6.2.4.png'),
        title: '管理升级',
        text: '实现线上走流程，让流程数字化，例如线上签合同，线上流程审批'
      }],
      forms2: [{
        img: require('@/assets/img/page6.3.1.png'),
        title: '可定制内容',
        text: '根据需求来定制功能，在产品原有的基础上进行定制开发，也可以全部定制自己的功能'
      }, {
        img: require('@/assets/img/page6.3.2.png'),
        title: '门店一码通',
        text: '一个门店一个二维码，扫码即可打开门店，进行商品购买，推广便利'
      }, {
        img: require('@/assets/img/page6.3.3.png'),
        title: '办公协同',
        text: '包含总经办、财务部、营销部、采购部、销售部、门店部等各部门业务'
      }, {
        img: require('@/assets/img/page6.3.4.png'),
        title: '组织权限',
        text: '可以添加员工角色，授权员工权限，定制不同部门以及不同部门菜单'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic1 {
  height: 2.02rem;
  margin-bottom: 0.16rem;
}
img {
 width: 100%;
}
.link1 {
  text-align: center;
  .link1_title {
    margin-bottom: 0.04rem;
    .link1_title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .link1_title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms1 {
    display: flex;
    justify-content: center;
    margin-left: 0.08rem;
    flex-wrap: wrap;
    .form1 {
      position: relative;
      width: 1.7rem;
      height: 2.16rem;
      margin-top: 0.12rem;
      margin-right: 0.09rem;
      border-radius: 0.02rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form1_icon {
        width: 1.53rem;
        margin: 0.07rem 0.07rem 0 0.07rem;
        .form1_img {
          width: 100%
        }
      }
      .form1_title {
        font-family: PingFangSC-Medium;
        margin-bottom: 0.02rem;
        font-size: 0.14rem;
      }
      .form1_text {
        font-family: PingFangSC-Regular;
        color: #333333;
        font-size: 0.11rem;
        margin: 0 0.07rem 0.13rem 0.07rem;;
      }
    }
  }
}
.link2 {
  background-color: #f7f7f7;
  text-align: center;
  padding-bottom: 0.01rem;
  .link2_title {
    padding-top: 0.2rem;
    .link2_title-big {
      font-size: 17px;
      font-family: PingFangSC-Medium;
      margin-bottom: 0.06rem;
    }
    .link2_title-small {
      font-family: PingFangSC-Regular;
      color: #666666;
    }
  }
  .forms2 {
    margin: 0.16rem 0.16rem  0 0.16rem;
    .form2 {
      position: relative;
      display: flex;
      align-items: center;
      height: 0.7rem;
      margin-bottom: 0.05rem;
      border-radius: 0.08rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form2_icon {
        margin: 0 0.06rem 0 0.1rem;
        width: 0.4rem;
      }
      .form2_solid {
        height: 0.355rem;
        margin-right: 0.19rem;
        border: 0.005rem solid #D3D2D2;
      }
      .form2_content {
        text-align: start;
        margin: 0.12rem 0 0.11rem 0;
        width: 2.585rem;
        .form2_title {
          margin-bottom: 0.02rem;
          font-family: PingFangSC-Medium;
          font-size: 0.13rem;
        }
        .form2_text {
          font-family: PingFangSC-Regular;
          color: #8C8C8C
        }
      }
    }
  }
}
</style>
